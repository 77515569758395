import { z } from 'zod';

export const userRegisterSchema = z
  .object({
    email: z
      .string({ required_error: 'Email requis' })
      .min(1, 'Email requis')
      .email('Email invalide'),
    name: z
      .string({ required_error: 'Nom requis' })
      .min(1, 'Nom requis')
      .max(30, 'Le Nom doit contenir moins de 30 caractères'),
    password: z
      .string({ required_error: 'Mot de passe requis' })
      .min(1, 'Mot de passe requis')
      .min(8, 'Le mot de passe doit contenir plus de 8 caractères')
      .max(32, 'Le mot de passe doit contenir moins de 32 caractères'),
    confirmPassword: z
      .string({ required_error: 'Confirmation du mot de passe requise' })
      .min(1, 'Confirmation du mot de passe requise')
      .min(8, 'La confirmation du mot de passe doit contenir plus de 8 caractères')
      .max(32, 'La confirmation du mot de passe doit contenir moins de 32 caractères'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Les mots de passe ne correspondent pas',
    path: ['confirmPassword'],
  });

export const userSignInSchema = z.object({
  email: z
    .string({ required_error: 'Email requis' })
    .min(1, 'Email requis')
    .email('Email invalide'),
  password: z
    .string({ required_error: 'Mot de passe requis' })
    .min(1, 'Mot de passe requis')
    .min(8, 'Le mot de passe doit contenir plus de 8 caractères')
    .max(32, 'Le mot de passe doit contenir moins de 32 caractères'),
});
