"use client";

import { Button } from "@/components/shadcn/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/shadcn/ui/form";
import { Input } from "@/components/shadcn/ui/input";
import { actionSubmit } from "@/components/uix/auth/auth_form/actions";
import CustomToast from "@/components/uix/CustomToast/CustomToast";
import { userSignInSchema } from "@/schemas/user/user_auth.schema";
import { UserSignInType } from "@/types/user/user.types";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { GoAlert } from "react-icons/go";

interface TAuthFormProps {}

const defaultValues: Partial<UserSignInType> = {
  email: "",
  password: "",
};

const AuthForm: FC<TAuthFormProps> = () => {
  const form = useForm<UserSignInType>({
    resolver: zodResolver(userSignInSchema),
    defaultValues,
    mode: "onChange",
  });

  const handleSubmit = async ({ email, password }: UserSignInType) => {
    try {
      await actionSubmit({ email, password });
      CustomToast({
        type: "success",
        titleText: "Connexion",
        descriptionText: "Votre connexion a bien été établie.",
        duration: 3000,
        icon: <GoAlert />,
      });
    } catch (err) {
      CustomToast({
        type: "error",
        titleText: "Attention !!",
        descriptionText: "Une erreur est survenue, veuillez réessayer.",
        duration: 3000,
        icon: <GoAlert />,
      });
    }
  };

  return (
    <>
      <div className='w-full flex flex-col bg-gradient-to-r from-primary/15 rounded border-2 border-primary/25 p-4'>
        <h1 className='text-xl'>Connexion</h1>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className='space-y-4'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>E-Mail</FormLabel>
                  <FormControl>
                    <Input {...field} type='text' placeholder='@' autoComplete='email' />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Mot de Passe</FormLabel>
                  <FormControl>
                    <Input {...field} type='password' placeholder='' autoComplete='new-password' />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type='submit' variant='outline' className='mt-1 text-primary'>
              Submit
            </Button>
          </form>
        </Form>
      </div>
    </>
  );
};

export default AuthForm;
