"use client";

import { FC } from "react";
import AuthForm from "@/components/uix/auth/auth_form/AuthForm";
import { useSession } from "next-auth/react";

type TPageProps = {};

const Page: FC<TPageProps> = (props: TPageProps) => {
  const { data } = useSession();

  //if (data !== null && data !== undefined) return redirect("/dashboard");

  return (
    <div className='flex justify-center bg-gradient-to-tl from-primary/5 p-5'>
      <div className='w-1/2 flex justify-center'>
        <AuthForm />
      </div>
    </div>
  );
};

export default Page;
