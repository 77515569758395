"use client";

import { toast } from "sonner";
import { cloneElement, ReactElement, ReactNode } from "react";

export interface CustomToastProps {
  type: "info" | "success" | "warning" | "error";
  titleText: string;
  descriptionText: string | ReactNode;
  duration?: number;
  icon?: ReactNode;
}

interface ToastTemplate {
  titleColor: string;
  descriptionColor: string;
  actionButtonColor: string;
  cancelButtonColor: string;
  closeButtonColor: string;
  classNames: string;
  iconColor: string;
}

const classNames = "bg-gradient-to-b from-primary/10";

const templates: Record<CustomToastProps["type"], ToastTemplate> = {
  success: {
    titleColor: "text-foreground",
    descriptionColor: "text-foreground",
    actionButtonColor: "bg-zinc-800",
    cancelButtonColor: "bg-orange-800",
    closeButtonColor: "bg-lime-800",
    classNames: classNames,
    iconColor: "text-green-500",
  },
  error: {
    titleColor: "text-foreground",
    descriptionColor: "text-foreground",
    actionButtonColor: "bg-zinc-800",
    cancelButtonColor: "bg-orange-800",
    closeButtonColor: "bg-red-800",
    classNames: classNames,
    iconColor: "text-red-500",
  },
  warning: {
    titleColor: "text-foreground",
    descriptionColor: "text-foreground",
    actionButtonColor: "bg-zinc-800",
    cancelButtonColor: "bg-orange-800",
    closeButtonColor: "bg-yellow-800",
    classNames: classNames,
    iconColor: "text-yellow-500",
  },
  info: {
    titleColor: "text-foreground",
    descriptionColor: "text-foreground",
    actionButtonColor: "bg-zinc-800",
    cancelButtonColor: "bg-orange-800",
    closeButtonColor: "bg-blue-800",
    classNames: classNames,
    iconColor: "text-blue-500",
  },
};

const CustomToast = ({
  type,
  titleText,
  descriptionText,
  duration = 3000,
  icon,
}: CustomToastProps) => {
  const template = templates[type];

  toast(titleText, {
    classNames: {
      toast: template.classNames,
      title: template.titleColor,
      description: template.descriptionColor,
      actionButton: template.actionButtonColor,
      cancelButton: template.cancelButtonColor,
      closeButton: template.closeButtonColor,
    },
    description: descriptionText,
    duration: duration,
    icon: icon
      ? cloneElement(icon as ReactElement<any>, {
          className: template.iconColor, // Applique la couleur à l'icône
        })
      : undefined,
  });
};

export default CustomToast;
